.optionCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.checkbox {
  margin-left: 10px;
  pointer-events: none;
}

.checkedText {
  color: var(--primary-color);
}