.fileInput {
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--white-color);
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px var(--shadow-color);
    padding: 7px 6px;
    width: 100%;
    min-height: 120px;
    outline: 1px dashed var(--dark-gray-color);
    outline-offset: -6px;
    transition: outline, outline-offset .2s ease-in-out;
}

.dropFile {
    outline-offset: -16px;
}

.error {
    outline: 1px dashed var(--red-color);
}

.fileContent {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.label {
    color: var(--primary-color);
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        text-decoration: underline;
    }
}

.input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
    top: 50%;
    left: 50%;
}

.fileName {
    margin-top: 25px;
    font: var(--font-s);
}