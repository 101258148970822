@import 'fonts/fonts';
@import 'variables/global';

/* Обнуление */
*{
  padding: 0;
  margin: 0;
  border: 0;
}
*,*::before,*::after{
  box-sizing: border-box;
}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}
a{color: #000;}
a,button,input{-webkit-tap-highlight-color: rgb(0 0 0 / 0%);}
nav,footer,header,aside{display: block;}

html,body{
  height: 100%;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  text-size-adjust: 100%;
}
input,button,textarea {font-family:inherit;}


input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner {
  padding:0;
  border:0;
}

/* a:hover{text-decoration: none;} */
ul li{list-style: none;}
img{vertical-align: top;}


h1,h2,h3,h4,h5,h6 {
  font-size:inherit;
  font-weight: 400;
}