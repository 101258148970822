
.xl {
  &.title {
    font: var(--font-xl);
    margin-bottom: 6px;
  }
    
  &.text {
    font: var(--font-m);
  }    
}

.m {
  &.title {
    font: var(--font-m);
    margin-bottom: 6px;
    font-weight: 700;
  }
    
  &.text {
    font: var(--font-s);
  }
    
}


.normal {
  .title, .text {
    color: var(--black-color);
  }
}

.error {
  .title, .text {
    color: var(--red-color);
  }
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}

