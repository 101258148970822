.reportDetailModal {
  max-width: 95%;
  width: 100%;
}

.table {
  padding-top: 50px;
  margin-bottom: 20px;
}

.btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}