
.errors {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.error {
    font: var(--font-s2);
    color: var(--red-color);
    font-weight: bold;
    padding: 0 17px;
}

.inputRow {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}