.appLink {
  text-decoration: none;
  font: var(--font-s);

  @media (max-width: 760px) {
    border-radius: 15px;
    box-shadow: 0px 4px 10px 0px var(--shadow-color);
    display: flex;
    align-items: center;
  }
}

.blue {
  color: var(--primary-color);
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
}


.button {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--dark-gray-color);
  color: var(--dark-gray-color);
  background: var(--white-color);

  &.active,
  &:hover {
    color: var(--primary-color);
    font-weight: 700;
    background: var(--primary-bg-color);
    border: none;
    box-shadow: 0px 4px 10px 0px var(--shadow-color);
  }
}