.switch {
  display: flex;
  align-items: center;
}


.input {
  display: none;
}

.label {
  padding-left: 16px + 33px;
  position: relative;
  cursor: pointer;
  font-weight: 700;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::after {
    left: 3px;
    background: var(--white-color);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transition: transform 0.2s ease;
  }

  &::before {
    width: 33px;
    height: 18.5px;
    left: 0;
    border-radius: 23px;
    background: var(--dark-gray-color);
  }
}


.input:checked {
  &+.label {
    &::before {
      background: var(--primary-color);
    }

    &::after {
      transform: translate(90%, -50%);
    }
  }
}