.addReportForm {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 26px;
}

.input {
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.btn {
  margin-top: 30px;
  padding: 10px;
  width: 193px;
}