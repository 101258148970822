.inputContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 100%;
  border-radius: 9px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: border .2s ease-in-out;
}

.error {
  border: 1px solid var(--red-color);
}

.success {
  border: 1px solid var(--green-color);
}

.gray {
  background-color: var(--gray-bg-color);
}

.white {
  background-color: var(--white-color);
  max-height: 35px;
  box-shadow: 0px 4px 10px 0px var(--shadow-color);
  display: flex;
  justify-content: center;
}

.input {
  background-color: transparent;
  padding: 17px 20px;
  columns: var(--black-color);
  max-height: 100%;
}

.label {
  font-weight: 700;
  margin-bottom: 6px;
}