.dropdownMenu {
  position: relative;
  min-width: 160px;

}

.preview {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 23px;
  box-shadow: 0 4px 10px 0 var(--shadow-color);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.text {
  margin-left: 8px;
  font-size: var(--font-size-s2);
}

.menu {
  position: absolute;
  display: grid;
  overflow: hidden;
  grid-template-rows: 0;
  opacity: 0;
  background: var(--gray-bg-color);
  width: 100%;
  border-radius: 0 0 23px 23px;
  transition: all 0.3s ease-in-out;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 16px 13px;
}

.button {
  font-size: var(--font-size-s2);
}

.pass {
  color: var(--primary-color);
  font-weight: var(--font-weight-xl);
}

.active {
  .menu {
    grid-template-rows: 1fr;
    opacity: 1;
  }

  .preview {
    position: relative;
    z-index: 100;
    background: var(--gray-bg-color);
    border-radius: 23px 23px 0 0;
    box-shadow: none;
  }
}