.icon {
  fill: var(--black-color);
  stroke: var(--black-color);
  height: 100%;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  width: 20px;
  height: 20px;
}

.link {
  display: flex;
  align-items: center;
  padding: 13px 0 13px 10px;

  &.active,
  &:hover {
    color: var(--white-color);
    background: var(--primary-color);
    font-weight: 700;
    box-shadow: 0px 4px 10px 0px var(--shadow-color);
  }

  @media (max-width: 760px) {
    justify-content: center;
  }
}

.link.active>.iconContainer>.icon,
.link:hover>.iconContainer>.icon {
  fill: var(--white-color);
  stroke: var(--white-color);
}

.collapsed {

  .link {
    justify-content: center;
  }

  .text {
    display: block;
    width: 0;
    overflow: hidden;

    @media (max-width: 760px) {
      overflow: auto;
      width: auto;
    }
  }
}