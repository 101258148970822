.changePasswordForm {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 40px;
}

.input {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.buttons {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
}

.btn {
  padding: 9px;
  width: 190px;
}