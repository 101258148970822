.dateInput {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.calendarForm {
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 50px;
  background: var(--gray-bg-color);
  border-radius: 10px;
  pointer-events: none;
  z-index: -1;
  box-shadow: 0px 4px 10px 0px var(--shadow-color);
  transition: opacity .2s ease-in-out;
}

.center {
  top: -100px;
}

.down {
  top: 270px;
}

.isFocused {
  opacity: 1;
  z-index: 100;
  pointer-events: all;
}

.buttons {
  display: flex;
  margin: 0 -30px;
  margin-top: 30px;
}

.button {
  margin: 0 30px;
  flex: 1 1 auto;
}