.notifications {
    position: relative;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    border-radius: 0 15px 15px 0;
    background-color: var(--gray-bg-color);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    min-height: 47px;
    max-width: var(--sidebar-width);
    transition: max-width 0.3s ease-in-out;
}

.collapsed {
    max-width: var(--sidebar-collapsed-width);
}

.alertBtn {
    position: absolute;
    top: 11px;
    right: -10px;
    transition: top 0.3s ease-in-out;

    &.active {
        top: 24px;
    }
}

.arrow {
    width: 5px;
    height: 9;

    &.active {
        transform: rotate(180deg);
    }
}