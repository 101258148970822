.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  font: var(--font-s2);
  font-weight: 700;
  color: var(--white-color);
  padding: 6px 8px;
}

.new {
  background: var(--dark-gray-color);
}

.coordination {
  background: var(--yellow-color);
}

.paymentCoordination {
  background: var(--yellow-color);
}

.inWork {
  background: var(--green-color);
}

.processed {
  background: var(--green-color);
}

.finished {
  background: var(--primary-color);
}