.tableItemBody {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 12px 43px;
  border-radius: 10px;
  border: 1px solid var(--table-border-color);
  background: var(--gray-bg-color);

  @media(max-width: 960px) {
    font-size: var(--font-size-s2);
    line-height: 15px;
    padding: 10px 15px;
  }
}

.normal {
  &:hover {
    .buttons {
      display: flex;
    }
  }

  .button svg {
    stroke: var(--black-color);
    fill: var(--black-color);
  }

}

.click {
  cursor: pointer;

  &:hover {
    background: var(--primary-color);
    color: var(--white-color);
  }

  &:hover .checkbox label::before {
    border: none;
    background: var(--primary-bg-color);
  }

  &:hover .checkbox:checked+label::before {
    background:
      var(--primary-bg-color) url('data:image/svg+xml,<svg viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.47059 8C4.35477 7.99842 4.24373 7.95339 4.15925 7.87376L1.10044 4.80019C1.03075 4.71488 0.995145 4.60657 1.00053 4.49634C1.00592 4.38611 1.05192 4.28183 1.12959 4.20379C1.20725 4.12575 1.31103 4.07952 1.42073 4.07411C1.53044 4.0687 1.63822 4.10448 1.72312 4.1745L4.47059 6.94071L10.2769 1.10092C10.3618 1.0309 10.4696 0.995122 10.5793 1.00054C10.689 1.00595 10.7927 1.05217 10.8704 1.13021C10.9481 1.20825 10.9941 1.31253 10.9995 1.42276C11.0049 1.533 10.9692 1.6413 10.8996 1.72661L4.78194 7.87376C4.69746 7.95339 4.58641 7.99842 4.47059 8Z" fill="%233CACDD" stroke="%233CACDD" stroke-width="0.8"/></svg>') center no-repeat;
  }

  &:hover {
    .buttons {
      display: flex;
    }
  }

  .button svg {
    stroke: var(--black-color);
    fill: var(--black-color);
  }
}

.link {
  &:hover {
    background: var(--primary-color);
    color: var(--white-color);

    .buttons {
      display: flex;
    }

    .button svg {
      fill: var(--white-color);
      stroke: var(--white-color);
    }
  }

  &:hover .checkbox label::before {
    border: none;
    background: var(--primary-bg-color);
  }

  &:hover .checkbox:checked+label::before {
    background:
      var(--primary-bg-color) url('data:image/svg+xml,<svg viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.47059 8C4.35477 7.99842 4.24373 7.95339 4.15925 7.87376L1.10044 4.80019C1.03075 4.71488 0.995145 4.60657 1.00053 4.49634C1.00592 4.38611 1.05192 4.28183 1.12959 4.20379C1.20725 4.12575 1.31103 4.07952 1.42073 4.07411C1.53044 4.0687 1.63822 4.10448 1.72312 4.1745L4.47059 6.94071L10.2769 1.10092C10.3618 1.0309 10.4696 0.995122 10.5793 1.00054C10.689 1.00595 10.7927 1.05217 10.8704 1.13021C10.9481 1.20825 10.9941 1.31253 10.9995 1.42276C11.0049 1.533 10.9692 1.6413 10.8996 1.72661L4.78194 7.87376C4.69746 7.95339 4.58641 7.99842 4.47059 8Z" fill="%233CACDD" stroke="%233CACDD" stroke-width="0.8"/></svg>') center no-repeat;
  }
}

.checkbox {
  position: relative;
  margin-right: 25px;
  z-index: 10;
}

.column {
  display: flex;
  flex-direction: column;
  &:first-child,
  &:nth-child(2) {
    align-items: flex-start;
  }
}

.buttons {
  display: flex;
  align-items: center;
  display: none;
  gap: 16px;
  margin: 0 16px;
}

.button {
  padding: 0 15px;
}

.columnButtons {
  position: absolute;
  right: 0px;
  align-items: flex-end;
}
.text-center {
  text-align: center;
  align-items: center;
  &:first-child,
  &:nth-child(2) {
    align-items: flex-start;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}