.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font: var(--font-s);
  color: var(--black-color);
}


.page-wrapper {
  flex: 1 1 100%;
  overflow: hidden;
  margin-right: 32px;

  @media (max-width: 760px) {
    margin: 20px auto;
    padding: 0 10px;
    width: 100%;
  }
}

.content-page {
  display: flex;
  flex: 1 1 100%;

  @media (max-width: 760px) {
    flex-direction: column-reverse;
    gap: 40px;
  }
}

// Calendar settings
.tp-calendar {
  &-header {
    p {
      font: var(--font-m);
      font-weight: 700;
      color: #000 !important;
    }
  }
}

.tp-calendar-month-select {
  display: flex;

  &>div {
    border: 1px solid var(--primary-color) !important;
    flex: 1 0 50% !important;
    padding: 10px;

    &:hover {
      background: var(--primary-color);

      p {
        color: #fff !important;
      }
    }
  }

  &>div p {
    color: #000 !important;
  }

}