.select {
  position: relative;
  width: 100%;
  cursor: pointer;
  background: var(--gray-bg-color);
  border-radius: 9px;
  appearance: none;
  border: 1px solid transparent;
  transition: border .2s ease-in-out;

  &.s {
    .input {
      padding: 5px 20px;
    }
  }

  &.m {
    input {
      padding: 15px 20px;
    }
  }

  &.a {
    input {
      padding: 0;
    }
  }
}

.singleSelect {
  min-width: 100px;
}

.input {
  position: relative;
  z-index: 10;
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: calc(100% - 20px);
  cursor: pointer;
}

.inputMulti {
  position: absolute;
  width: 100%;
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 15px 20px;
  width: calc(100% - 20px);
}

.singleSelect {
  min-width: 100px;
}

.input {
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.inputMulti {
  position: absolute;
  width: 100%;
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 7px 15px;
}

.error {
  border: 1px solid var(--red-color);
}

.selectedItem {
  display: block;
  cursor: pointer;
}

.optionsList {
  display: none;
  min-width: 100%;
  width: max-content;
  position: absolute;
  overflow-y: auto;
  max-height: 35vh;
  left: 0;
  top: 100%;
  z-index: 20;
  border-radius: 10px;
  background: var(--white-color);
  box-shadow: 0 4px 10px 0 var(--shadow-color);
}

.optionsList li {
  padding: 10px;
  cursor: pointer;
}

.optionsList li:hover {
  background-color: #f0f0f0;
}

.selectedItem:hover .optionsList {
  display: block;
}

.open {
  .optionsList {
    display: block;
  }

  &.select::before {
    transform: translateY(-50%) rotate(-90deg);
  }

  &.active::before {
    transform: translateY(-50%) rotate(-180deg);
  }
}

.select::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) rotate(90deg);
  background: url('../../assets/icons/arrow-icon-right.svg') center no-repeat;
  display: block;
  width: 10px;
  height: 10px;
}

.selectedList {
  z-index: 5;
  display: flex;
  flex-direction: row;
  gap: 6px;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: calc(100% - 55px);
}

.inputMulti:not(:focus)~.selectedList {
  display: flex;
}

.inputMulti:focus~.selectedList {
  display: none;
}

.selected {
  font-size: var(--font-size-s2);
  line-height: var(--font-line-s);
  display: flex;
  flex-direction: row;
  gap: 6px;
  border-radius: 10px;
  border: 1px solid var(--black-color);
  padding: 2px 10px;

  flex: 1 1 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  button {
    margin-left: auto;
  }
}

.more {
  font-size: var(--font-size-s2);
  line-height: var(--font-line-s);
  padding: 2px 6px;
  border-radius: 10px;
  background-color: var(--primary-bg-color);
}

.border {
  padding: 5px 16px;
  border-radius: 13px;
  border: 1px solid var(--dark-gray-bg-color);
  background-color: var(--white-color);

  .selectedItem {
    font-size: var(--font-size-s2);
    color: var(--dark-gray-bg-color);
    line-height: 12px;
    font-weight: var(--font-weight-xl);
  }
}

.active {
  padding: 5px 16px;
  border-radius: 13px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-bg-color);

  .selectedItem {
    font-size: var(--font-size-s2);
    color: var(--primary-color);
    line-height: 12px;
    font-weight: var(--font-weight-xl);
  }
}

.active::before {
  transform: translateY(-50%);
  background: url('../../assets/icons/blue-arrow-icon.svg') center no-repeat;
}