@font-face {
  font-family: 'Calibri';
  src: url("../../../shared/assets/fonts/Calibri_Bold.TTF");
  font-weight: 700;
}

@font-face {
  font-family: 'Calibri';
  src: url("../../../shared/assets/fonts/Calibri_Regular.ttf");
  font-weight: 400;
}